import { replaceAll } from "_common/service/FunUtil";
import IconMembres from "_common/component/icon/Membre.jsx";
import IconCoupDePouce from "_common/component/icon/CoupDePouce.jsx";
import IconAgenda from "_common/component/icon/calendar.svg";
import IconEvenements from "_common/component/icon/Calendar.jsx";
import IconPublications from "_common/component/icon/Publication.jsx";
import IconTerritoires from "_common/component/icon/Territoire.jsx";
import IconAdminEtCoordinatrice from "_common/component/icon/AdminEtCoordinatrice.jsx";
import IconParametres from "_common/component/icon/settings.svg";
import IconProfil from "_common/component/icon/profil.svg";
import IconRessources from "_common/component/icon/Ressource.jsx";
import SignOut from "_common/component/icon/signOut.svg";
import IconHome from "_common/component/icon/Home.jsx";
import { logout } from "_core/authentification/service/AuthService";
import reactLazyWithRetry from "@fatso83/retry-dynamic-import/react-lazy";

const AdhesionEnding = reactLazyWithRetry(() => import("_core/adhesion/component/AdhesionEnding"));
const AdhesionPayement = reactLazyWithRetry(() => import("_core/adhesion/component/AdhesionPayement"));
const AdministratriceExport = reactLazyWithRetry(() => import("business/back-office/administratrice/page-administratrice-export/AdministratriceExport"));
const AdministratriceTerritoireCreation = reactLazyWithRetry(() => import("business/territoire/page-administratrice-territoire-creation/AdministratriceTerritoireCreation"));
const AdministratriceTerritoireEdition = reactLazyWithRetry(() => import("business/territoire/page-administratrice-territoire-creation/AdministratriceTerritoireCreation"));
const AdministratriceAdherenteList = reactLazyWithRetry(() => import("business/back-office/administratrice/page-admininstratrice-adherente/AdministratriceAdherenteList"));
const AdministratriceAdherenteEdition = reactLazyWithRetry(() => import("business/back-office/administratrice/page-administratrice-adherente-edition/AdministratriceAdherenteEdition"));
const AdministratriceEvenementList = reactLazyWithRetry(() => import("business/back-office/administratrice/page-administratrice-evenement/AdministratriceEvenementList"));
const AdministratriceEvenementCreation = reactLazyWithRetry(() => import("business/back-office/administratrice/page-administratrice-evenement-creation/AdministratriceEvenementCreation"));
const AdministratriceEvenementEdition = reactLazyWithRetry(() => import("business/back-office/administratrice/page-administratrice-evenement-edition/AdministratriceEvenementEdition"));
const AdministratriceEvenementParticipantes = reactLazyWithRetry(() => import("business/back-office/administratrice/page-administratrice-evenement-participantes/AdministratriceEvenementParticipantes"));
const AdministratricePublication = reactLazyWithRetry(() => import("business/back-office/administratrice/page-administratrice-publication/AdministratricePublication"));
const AdministratriceRessourceList = reactLazyWithRetry(() => import("business/back-office/administratrice/page-administratrice-ressource/AdministratriceRessourceList"));
const AdministratriceRessourceCreation = reactLazyWithRetry(() => import("business/back-office/administratrice/page-administratrice-ressource-creation/AdministratriceRessourceCreation"));
const AdministratriceRessourceCreationEdition = reactLazyWithRetry(() => import("business/ressource/page-administratrice-ressource-creation-edition/RessourceCreationEdition"));
const AdministratriceTerritoireList = reactLazyWithRetry(() => import("business/back-office/administratrice/page-administratrice-territoire/AdministratriceTerritoireList"));
const AuthentificationConnexion = reactLazyWithRetry(() => import("_core/authentification/component/connexion/AuthentificationConnexion"));
const AuthentificationInscription = reactLazyWithRetry(() => import("_core/authentification/component/inscription/AuthentificationInscription"));
const AuthentificationParametreCompte = reactLazyWithRetry(() => import("_core/authentification/component/parametre-compte/AuthentificationParametreCompte"));
const AuthentificationSetPassword = reactLazyWithRetry(() => import("_core/authentification/component/mot-de-passe/AuthentificationSetPassword"));
const AuthentificationResetForgottenPassword = reactLazyWithRetry(() => import("_core/authentification/component/mot-de-passe/AuthentificationResetForgottenPassword"));
const CoordinatriceAdherenteList = reactLazyWithRetry(() => import("business/back-office/coordinatrice/page-coordinatrice-adherente/CoordinatriceAdherenteList"));
const CoordinatriceEvenementList = reactLazyWithRetry(() => import("business/back-office/coordinatrice/page-coordinatrice-evenement/CoordinatriceEvenementList"));
const CoordinatriceEvenementCreation = reactLazyWithRetry(() => import("business/back-office/coordinatrice/page-coordinatrice-evenement-creation/CoordinatriceEvenementCreation"));
const CoordinatriceEvenementEdition = reactLazyWithRetry(() => import("business/back-office/coordinatrice/page-coordinatrice-evenement-edition/CoordinatriceEvenementEdition"));
const CoordinatriceEvenementParticipantes = reactLazyWithRetry(() => import("business/back-office/coordinatrice/page-coordinatrice-evenement-participantes/CoordinatriceEvenementParticipantes"));
const CoordinatriceRessources = reactLazyWithRetry(() => import("business/back-office/coordinatrice/page-coordinatrice-cloud/CoordinatriceRessources"));
const CoupDePouceCreation = reactLazyWithRetry(() => import("business/coup-de-pouce/page-coup-de-pouce-creation/CoupDePouceCreation"));
const CoupDePouceEdition = reactLazyWithRetry(() => import("business/coup-de-pouce/page-coup-de-pouce-creation/CoupDePouceCreation"));
const CoupDePouceDetail = reactLazyWithRetry(() => import("business/coup-de-pouce/page-coup-de-pouce-detail/CoupDePouceDetail"));
const CoupDePouceList = reactLazyWithRetry(() => import("business/coup-de-pouce/page-coup-de-pouce-list/CoupDePouceList"));
const EvenementAgenda = reactLazyWithRetry(() => import("business/evenement/page-evenement-agenda/EvenementAgenda"));
const EvenementDetail = reactLazyWithRetry(() => import("business/evenement/page-evenement-detail/EvenementDetail"));
const EvenementList = reactLazyWithRetry(() => import("business/evenement/page-evenement-list/EvenementList"));
const EvenementParticipantList = reactLazyWithRetry(() => import("business/evenement/page-evenement-participant-list/EvenementParticipantList"));
const Home = reactLazyWithRetry(() => import("business/home/HomePage"));
const MembreProfil = reactLazyWithRetry(() => import("business/membre/page-membre-profil/MembreProfil"));
const MembreProfilEdition = reactLazyWithRetry(() => import("business/membre/page-membre-profil-edition/MembreProfilEdition"));
const MembreList = reactLazyWithRetry(() => import("business/membre/page-membre-list/MembreList"));
const MessageEdition = reactLazyWithRetry(() => import("business/messagerie/component/messagerie-message-creation/MessagerieMessageCreation"));
const Messagerie = reactLazyWithRetry(() => import("business/messagerie/page-messagerie/Messagerie"));
const MessagerieDiscussionDetail = reactLazyWithRetry(() => import("business/messagerie/page-messagerie-discussion-detail/MessagerieDiscussionDetail"));
const MessagerieDiscussionNew = reactLazyWithRetry(() => import("business/messagerie/page-messagerie-discussion-new/MessagerieDiscussionNew"));
const NotificationList = reactLazyWithRetry(() => import("business/notification/page-notification/Notification"));
const PublicationEdition = reactLazyWithRetry(() => import("business/publication/component/publication-creation/PublicationCreation"));
const PublicationDetail = reactLazyWithRetry(() => import("business/publication/page-publication-detail/PublicationDetail"));
const PublicationList = reactLazyWithRetry(() => import("business/publication/page-publication-list/PublicationList"));
const CommentaireEtReponseEdition = reactLazyWithRetry(() => import("business/publication/component/publication-commentaire-creation/PublicationCommentaireCreation"));
const RessourceList = reactLazyWithRetry(() => import("business/ressource/page-ressource-list/RessourceList"));
const RessourceArticleDetail = reactLazyWithRetry(() => import("business/ressource/page-article-detail/ArticleDetail"));
const TerritoireCoupDePouceList = reactLazyWithRetry(() => import("business/coup-de-pouce/page-territoire-coup-de-pouce-list-desktop/TerritoireCoupDePouceList"));
const TerritoireDetail = reactLazyWithRetry(() => import("business/territoire/page-territoire-detail/TerritoireDetail"));
const TerritoireEvenementList = reactLazyWithRetry(() => import("business/evenement/page-territoire-evenement-list-desktop/TerritoireEvenementList"));
const TerritoireListAndMap = reactLazyWithRetry(() => import("business/territoire/page-territoire-list-map/TerritoireListAndMap"));
const TerritoireMembreList = reactLazyWithRetry(() => import("business/membre/page-territoire-membre-list-desktop/TerritoireMembreList"));
const TerritoirePublicationList = reactLazyWithRetry(() => import("business/publication/page-territoire-publication-list-desktop/TerritoirePublicationList"));

export const ROUTE_ADHESION_PAIEMENT = "/adhesion-paiement";
export const ROUTE_ADMIN_USER_LIST = "/admin/users";
export const ROUTE_ADMINISTRATRICE_ADHERENTE = "/administratrice/adherente";
export const ROUTE_ADMINISTRATRICE_ADHERENTE_EDIT = "/administratrice/adherente/:id/edition";
export const ROUTE_ADMINISTRATRICE_EVENEMENT = "/administratrice";
export const ROUTE_ADMINISTRATRICE_EVENEMENT_CREATE = "/administratrice/evenement/creation";
export const ROUTE_ADMINISTRATRICE_EVENEMENT_EDIT = "/administratrice/evenement/:id/edition";
export const ROUTE_ADMINISTRATRICE_EVENEMENT_PAGE = "/administratrice/evenement";
export const ROUTE_ADMINISTRATRICE_EVENEMENT_PARTICIPANTES = "/administratrice/evenement/:id/participantes/page/:pagenum";
export const ROUTE_ADMINISTRATRICE_EXPORT = "/administratrice/export";
export const ROUTE_ADMINISTRATRICE_PUBLICATION = "/administratrice/actu";
export const ROUTE_ADMINISTRATRICE_RESSOURCE = "/administratrice/ressource";
export const ROUTE_ADMINISTRATRICE_RESSOURCE_CREATE = "/administratrice/ressource/creation";
export const ROUTE_ADMINISTRATRICE_RESSOURCE_CREATE_LIEN = "/administratrice/ressource/creation/lien";
export const ROUTE_ADMINISTRATRICE_RESSOURCE_CREATE_ARTICLE = "/administratrice/ressource/creation/article";
export const ROUTE_ADMINISTRATRICE_RESSOURCE_EDIT_LIEN = "/administratrice/ressource/lien/:id/edition";
export const ROUTE_ADMINISTRATRICE_RESSOURCE_EDIT_ARTICLE = "/administratrice/ressource/article/:id/edition";
export const ROUTE_ADMINISTRATRICE_TERRITOIRE_CREATE = "/administratrice/antenne/creation";
export const ROUTE_ADMINISTRATRICE_TERRITOIRE_EDIT = "/administratrice/antenne/:id/edition";
export const ROUTE_ADMINISTRATRICE_TERRITOIRE_LIST = "/administratrice/antenne";
export const ROUTE_ADMINISTRATRICE_TERRITOIRE_PAGE = "/administratrice/antenne/page/:pagenum/nom/:trinom/region/:triregion";
export const ROUTE_AGENDA = "/evenement/agenda/futur/:futur/page/:pagenum";
export const ROUTE_CARTE_TERRITOIRE = "/antenne";
export const ROUTE_CONDITIONS_UTILISATIONS = "https://www.femmesdesterritoires.fr/cgv-cgu/";
export const ROUTE_CONNEXION_APRES_VALIDATION_EMAIL = "/connexion/:token/:creation";
export const ROUTE_CONNEXION = "/connexion";
export const ROUTE_COORDINATRICE_ADHERENTE = "/coordinatrice/adherente";
export const ROUTE_COORDINATRICE_EVENEMENT = "/coordinatrice/evenement";
export const ROUTE_COORDINATRICE_EVENEMENT_CREATE = "/coordinatrice/evenement/creation";
export const ROUTE_REPONSE_CDP_EDITION = "/coup-de-pouce/:id/reponse/:reponseId/edition"
export const ROUTE_COORDINATRICE_EVENEMENT_EDIT = "/coordinatrice/evenement/:id/edition";
export const ROUTE_COORDINATRICE_EVENEMENT_PAGE = "/coordinatrice/evenement";
export const ROUTE_COORDINATRICE_EVENEMENT_PARTICIPANTES = "/coordinatrice/evenement/:id/participantes/page/:pagenum";
export const ROUTE_COORDINATRICE_RESSOURCES = "/coordinatrice/ressources";
export const ROUTE_COUPS_DE_POUCE = "/coup-de-pouce";
export const ROUTE_COUP_DE_POUCE_CREATE = "/coup-de-pouce/creation";
export const ROUTE_COUP_DE_POUCE_EDIT = "/coup-de-pouce/:id/edition";
export const ROUTE_COUP_DE_POUCE_DETAIL = "/coup-de-pouce/:id";
export const ROUTE_EVENEMENTS = "/evenement";
export const ROUTE_EVENEMENT_DETAIL = "/evenement/:id";
export const ROUTE_EVENEMENT_PARTICIPANT_LIST = "/evenement/:id/participant";
export const ROUTE_EVENEMENT_PARTICIPANT_PAGE = "/evenement/:id/participant/page/:pagenum";
export const ROUTE_FIN_ADHESION = "/fin-adhesion";
export const ROUTE_HOME = "/";
export const ROUTE_INSCRIPTION = "/inscription"
export const ROUTE_MEMBRES = "/membre";
export const ROUTE_MENTIONS_LEGALES = "https://www.femmesdesterritoires.fr/mentions-legales/";
export const ROUTE_MESSAGE_EDITION = "/message/:messageId/edition";
export const ROUTE_MESSAGERIE = "/messagerie";
export const ROUTE_MESSAGERIE_DISCUSSION_DETAIL = "/messagerie/discussion/:id";
export const ROUTE_MESSAGERIE_NOUVELLE_DISCUSSION = "/messagerie/discussion";
export const ROUTE_MON_COMPTE = "/mon-compte";
export const ROUTE_MON_PROFIL = "/mon-profil";
export const ROUTE_NOTIFICATIONS = "/notifications";
export const ROUTE_NOUVEAU_MDP = "/reinitialisation-mot-de-passe/nouveau-mot-de-passe";
export const ROUTE_PROFIL = "/membre/:id";
export const ROUTE_PROFIL_EDIT = "/membre/:id/edition";
export const ROUTE_PARAMETRES = "/membre/:id/parametres";
export const ROUTE_POLITIQUE_CONFIDENTIALITE = "https://www.femmesdesterritoires.fr/politique-des-cookies/";
export const ROUTE_PUBLICATIONS_COMMENTAIRE_EDITION = "/actu/:id/commentaire/:commentaireId/edition"
export const ROUTE_PUBLICATIONS = "/actu";
export const ROUTE_PUBLICATIONS_DETAIL = "/actu/:id"
export const ROUTE_PUBLICATIONS_EDITION = "/actu/:id/edition"
export const ROUTE_REINITIALISATION_MDP = "/reinitialisation-mot-de-passe";
export const ROUTE_RESSOURCES = "/ressource";
export const ROUTE_RESSOURCES_ARTICLE_DETAIL = "/ressource/article/:id";
export const ROUTE_TERRITOIRE_DETAIL = "/antenne/:id";
export const ROUTE_TERRITOIRE_PUBLICATION = "/antenne/:id/publication";
export const ROUTE_TERRITOIRE_CDP = "/antenne/:id/coup-de-pouce";
export const ROUTE_TERRITOIRE_EVENEMENT = "/antenne/:id/evenement";
export const ROUTE_TERRITOIRE_MEMBRE = "/antenne/:id/membre";

const routes = [
  {
    path: ROUTE_MON_COMPTE,
    key: "parametre",
    element: <AuthentificationParametreCompte />,
    secured: true,
  },
  {
    path: ROUTE_PARAMETRES,
    key: "parametre",
    element: <AuthentificationParametreCompte />,
    secured: true,
    menuBurgerMobile: {
      label: "Mon compte",
      icon: { type: "svg", value: IconParametres },
      position: 30,
    },
  },
  {
    path: ROUTE_PROFIL_EDIT,
    key: "profil-edit",
    element: <MembreProfilEdition />,
    secured: true,
  },
  {
    path: ROUTE_MON_PROFIL,
    key: "profil",
    element: <MembreProfil />,
    secured: true,
  },
  {
    path: ROUTE_PROFIL,
    key: "profil",
    element: <MembreProfil />,
    secured: true,
    menuBurgerMobile: {
      label: "Mon profil",
      icon: { type: "svg", value: IconProfil },
      position: 10,
    },
  },
  {
    path: ROUTE_MEMBRES,
    key: "membres",
    element: <MembreList />,
    secured: true,
    menu: {
      label: "Membres",
      icon: { type: "jsx", value: IconMembres },
      position: 10,
    },
    menuMobile: {
      label: "Membres",
      icon: { type: "jsx", value: IconMembres },
      position: 20,
    },
  },
  {
    path: ROUTE_COUP_DE_POUCE_CREATE,
    key: "coups-de-pouce-creation",
    element: <CoupDePouceCreation />,
    secured: true,
  },
  {
    path: ROUTE_REPONSE_CDP_EDITION,
    key: "coup-de-pouce-detail",
    element: <CommentaireEtReponseEdition />,
    secured: true,
  },
  {
    path: ROUTE_COUP_DE_POUCE_EDIT,
    key: "coups-de-pouce-edition",
    element: <CoupDePouceEdition />,
    secured: true,
  },
  {
    path: ROUTE_COUP_DE_POUCE_DETAIL,
    key: "coup-de-pouce-detail",
    element: <CoupDePouceDetail />,
    secured: true,
  },
  {
    path: ROUTE_COUPS_DE_POUCE,
    key: "coups-de-pouce",
    element: <CoupDePouceList />,
    secured: true,
    menu: {
      label: "Coups de pouce",
      icon: { type: "jsx", value: IconCoupDePouce },
      position: 20,
    },
    menuMobile: {
      label: "Coups de pouce",
      icon: { type: "jsx", value: IconCoupDePouce },
      position: 30,
    },
  },
  {
    path: ROUTE_AGENDA,
    key: "agenda",
    element: <EvenementAgenda />,
    secured: true,
    menuBurgerMobile: {
      label: "Mon agenda",
      icon: { type: "svg", value: IconAgenda },
      position: 40,
    },
  },
  {
    path: ROUTE_EVENEMENT_DETAIL,
    key: "evenements-detail",
    element: <EvenementDetail />,
    secured: true,
  },
  {
    path: ROUTE_EVENEMENT_PARTICIPANT_LIST,
    key: "evenements-participant-list",
    element: <EvenementParticipantList />,
    secured: true,
  },
  {
    path: ROUTE_EVENEMENT_PARTICIPANT_PAGE,
    key: "evenements-participant-list",
    element: <EvenementParticipantList />,
    secured: true,
  },
  {
    path: ROUTE_EVENEMENTS,
    key: "evenements",
    element: <EvenementList />,
    secured: true,
    menu: {
      label: "Événements",
      icon: { type: "jsx", value: IconEvenements },
      position: 30,
    },
    menuMobile: {
      label: "Événements",
      icon: { type: "jsx", value: IconEvenements },
      position: 40,
    },
  },
  {
    path: ROUTE_PUBLICATIONS_DETAIL,
    key: "publications",
    element: <PublicationDetail />,
    secured: true,
  },
  {
    path: ROUTE_PUBLICATIONS_EDITION,
    key: "publications-edition",
    element: <PublicationEdition />,
    secured: true,
  },
  {
    path: ROUTE_PUBLICATIONS_COMMENTAIRE_EDITION,
    key: "publications-commentaire-edition",
    element: <CommentaireEtReponseEdition />,
    secured: true,
  },
  {
    path: ROUTE_PUBLICATIONS,
    key: "publications",
    element: <PublicationList />,
    secured: true,
    menu: {
      label: "Fil d'actu",
      icon: { type: "jsx", value: IconPublications },
      position: 40,
    },
    menuMobile: {
      label: "Fil d'actu",
      icon: { type: "jsx", value: IconPublications },
      position: 50,
    },
  },
  {
    path: ROUTE_RESSOURCES_ARTICLE_DETAIL,
    key: "ressources",
    element: <RessourceArticleDetail />,
    secured: true,
  },
  {
    path: ROUTE_RESSOURCES,
    key: "ressources",
    element: <RessourceList />,
    secured: true,
    menu: {
      label: "Ressources",
      icon: { type: "jsx", value: IconRessources },
      position: 60,
    },
    menuMobile: {
      label: "Ressources",
      icon: { type: "jsx", value: IconRessources },
      position: 60,
    },
  },
  {
    path: ROUTE_TERRITOIRE_PUBLICATION,
    key: "territoires",
    element: <TerritoirePublicationList />,
    secured: true,
  },
  {
    path: ROUTE_TERRITOIRE_EVENEMENT,
    key: "territoires",
    element: <TerritoireEvenementList />,
    secured: true,
  },
  {
    path: ROUTE_TERRITOIRE_CDP,
    key: "territoires-pages",
    element: <TerritoireCoupDePouceList />,
    secured: true,
  },
  {
    path: ROUTE_TERRITOIRE_MEMBRE,
    key: "territoires",
    element: <TerritoireMembreList />,
    secured: true,
  },
  {
    path: ROUTE_TERRITOIRE_DETAIL,
    key: "territoires",
    element: <TerritoireDetail />,
    secured: true,
  },
  {
    path: ROUTE_CARTE_TERRITOIRE,
    key: "territoires",
    element: <TerritoireListAndMap />,
    secured: true,
    menu: {
      label: "Antennes",
      icon: { type: "jsx", value: IconTerritoires },
      position: 50,
    },
    menuMobile: {
      label: "Antennes",
      icon: { type: "jsx", value: IconTerritoires },
      position: 50,
    },
  },
  {
    path: ROUTE_ADMINISTRATRICE_EXPORT,
    key: "administratrice",
    element: <AdministratriceExport />,
    secured: true,
    roles: ["ROLE_ADMINISTRATRICE"],
  },
  {
    path: ROUTE_ADMINISTRATRICE_ADHERENTE,
    key: "administratrice",
    element: <AdministratriceAdherenteList />,
    secured: true,
    roles: ["ROLE_ADMINISTRATRICE"],
  },
  {
    path: ROUTE_ADMINISTRATRICE_PUBLICATION,
    key: "administratrice",
    element: <AdministratricePublication />,
    secured: true,
    roles: ["ROLE_ADMINISTRATRICE"],
  },
  {
    path: ROUTE_ADMINISTRATRICE_RESSOURCE,
    key: "administratrice",
    element: <AdministratriceRessourceList />,
    secured: true,
    roles: ["ROLE_ADMINISTRATRICE"],
  },
  {
    path: ROUTE_ADMINISTRATRICE_RESSOURCE_CREATE,
    key: "administratrice",
    element: <AdministratriceRessourceCreation />,
    secured: true,
    roles: ["ROLE_ADMINISTRATRICE"],
  },
  {
    path: ROUTE_ADMINISTRATRICE_RESSOURCE_CREATE_LIEN,
    key: "administratrice",
    element: <AdministratriceRessourceCreationEdition />,
    secured: true,
    roles: ["ROLE_ADMINISTRATRICE"],
  },
  {
    path: ROUTE_ADMINISTRATRICE_RESSOURCE_CREATE_ARTICLE,
    key: "administratrice",
    element: <AdministratriceRessourceCreationEdition />,
    secured: true,
    roles: ["ROLE_ADMINISTRATRICE"],
    exact: true
  },
  {
    path: ROUTE_ADMINISTRATRICE_RESSOURCE_EDIT_LIEN,
    key: "administratrice",
    element: <AdministratriceRessourceCreationEdition />,
    secured: true,
    roles: ["ROLE_ADMINISTRATRICE"],
  },
  {
    path: ROUTE_ADMINISTRATRICE_RESSOURCE_EDIT_ARTICLE,
    key: "administratrice",
    element: <AdministratriceRessourceCreationEdition />,
    secured: true,
    roles: ["ROLE_ADMINISTRATRICE"],
  },
  {
    path: ROUTE_ADMINISTRATRICE_TERRITOIRE_LIST,
    key: "administratrice",
    element: <AdministratriceTerritoireList />,
    secured: true,
    roles: ["ROLE_ADMINISTRATRICE"],
  },
  {
    path: ROUTE_ADMINISTRATRICE_ADHERENTE_EDIT,
    key: "administratrice",
    element: <AdministratriceAdherenteEdition />,
    secured: true,
    roles: ["ROLE_ADMINISTRATRICE"],
  },
  {
    path: ROUTE_ADMINISTRATRICE_TERRITOIRE_PAGE,
    key: "administratrice",
    element: <AdministratriceTerritoireList />,
    secured: true,
    roles: ["ROLE_ADMINISTRATRICE"],
  },
  {
    path: ROUTE_ADMINISTRATRICE_EVENEMENT_CREATE,
    key: "administratrice",
    element: <AdministratriceEvenementCreation />,
    secured: true,
    roles: ["ROLE_ADMINISTRATRICE"],
  },
  {
    path: ROUTE_ADMINISTRATRICE_TERRITOIRE_CREATE,
    key: "administratrice",
    element: <AdministratriceTerritoireCreation />,
    secured: true,
    roles: ["ROLE_ADMINISTRATRICE"],
  },
  {
    path: ROUTE_ADMINISTRATRICE_TERRITOIRE_EDIT,
    key: "administratrice",
    element: <AdministratriceTerritoireEdition />,
    secured: true,
    roles: ["ROLE_ADMINISTRATRICE"],
  },
  {
    path: ROUTE_ADMINISTRATRICE_EVENEMENT_EDIT,
    key: "administratrice",
    element: <AdministratriceEvenementEdition />,
    secured: true,
    roles: ["ROLE_ADMINISTRATRICE"],
  },
  {
    path: ROUTE_ADMINISTRATRICE_EVENEMENT_PARTICIPANTES,
    key: "administratrice",
    element: <AdministratriceEvenementParticipantes />,
    secured: true,
    roles: ["ROLE_ADMINISTRATRICE"],
  },
  {
    path: ROUTE_ADMINISTRATRICE_EVENEMENT_PAGE,
    key: "administratrice",
    element: <AdministratriceEvenementList />,
    secured: true,
    roles: ["ROLE_ADMINISTRATRICE"],
  },
  {
    path: ROUTE_ADMINISTRATRICE_EVENEMENT,
    key: "administratrice",
    element: <AdministratriceEvenementList />,
    secured: true,
    roles: ["ROLE_ADMINISTRATRICE"],
    menu: {
      label: "Administratrice",
      icon: { type: "jsx", value: IconAdminEtCoordinatrice },
      position: 70,
    }
  },
  {
    path: ROUTE_COORDINATRICE_ADHERENTE,
    key: "coordinatrice",
    element: <CoordinatriceAdherenteList />,
    secured: true,
    roles: ["ROLE_COORDINATRICE"],
  },
  {
    path: ROUTE_COORDINATRICE_EVENEMENT_CREATE,
    key: "coordinatrice",
    element: <CoordinatriceEvenementCreation />,
    secured: true,
    roles: ["ROLE_COORDINATRICE"],
  },
  {
    path: ROUTE_COORDINATRICE_EVENEMENT_EDIT,
    key: "coordinatrice",
    element: <CoordinatriceEvenementEdition />,
    secured: true,
    roles: ["ROLE_COORDINATRICE"],
  },
  {
    path: ROUTE_COORDINATRICE_EVENEMENT_PARTICIPANTES,
    key: "coordinatrice",
    element: <CoordinatriceEvenementParticipantes />,
    secured: true,
    roles: ["ROLE_COORDINATRICE"],
  },
  {
    path: ROUTE_COORDINATRICE_RESSOURCES,
    key: "coordinatrice",
    element: <CoordinatriceRessources />,
    secured: true,
    roles: ["ROLE_COORDINATRICE"],
  },
  {
    path: ROUTE_COORDINATRICE_EVENEMENT_PAGE,
    key: "coordinatrice",
    element: <CoordinatriceEvenementList />,
    secured: true,
    roles: ["ROLE_COORDINATRICE"],
  },
  {
    path: ROUTE_COORDINATRICE_EVENEMENT,
    key: "coordinatrice",
    element: <CoordinatriceEvenementList />,
    secured: true,
    roles: ["ROLE_COORDINATRICE"],
    menu: {
      label: "Coordinatrice",
      icon: { type: "jsx", value: IconAdminEtCoordinatrice },
      position: 80
    }
  },
  {
    path: ROUTE_CONDITIONS_UTILISATIONS,
    key: "cgu",
    secured: true,
    elementsJuridiques: {
      label: "Conditions d'utilisation",
      position: 1,
    },
  },
  {
    path: ROUTE_POLITIQUE_CONFIDENTIALITE,
    key: "politique-de-confidentialite",
    secured: true,
    exact: true,
    elementsJuridiques: {
      label: "Politique de confidentialité",
      position: 20,
    },
  },
  {
    path: ROUTE_MENTIONS_LEGALES,
    key: "mentions-legales",
    secured: true,
    elementsJuridiques: {
      label: "Mentions légales",
      position: 30,
    },
  },
  {
    path: ROUTE_INSCRIPTION,
    key: "inscription",
    element: <AuthentificationInscription />,
    secured: true,
  },
  {
    path: ROUTE_REINITIALISATION_MDP,
    key: "reinitialisation-mot-de-passe",
    element: <AuthentificationResetForgottenPassword />,
    secured: true,
  },
  {
    path: ROUTE_NOUVEAU_MDP,
    key: "reinitialisation-mot-de-passe",
    element: <AuthentificationSetPassword />,
    secured: true,
  },
  {
    path: ROUTE_MESSAGE_EDITION,
    key: "message-edition",
    element: <MessageEdition />,
    secured: true,
  },
  {
    path: ROUTE_MESSAGERIE_NOUVELLE_DISCUSSION,
    key: "nouvelleDiscussion",
    element: <MessagerieDiscussionNew />,
    secured: true,
  },
  {
    path: ROUTE_MESSAGERIE_DISCUSSION_DETAIL,
    key: "discussionDetail",
    element: <MessagerieDiscussionDetail />,
    secured: true,
  },
  {
    path: ROUTE_MESSAGERIE,
    key: "messagerie",
    element: <Messagerie />,
    secured: true,
  },
  {
    path: ROUTE_NOTIFICATIONS,
    key: "notifications",
    element: <NotificationList />,
    secured: true,
  },
  {
    path: ROUTE_ADHESION_PAIEMENT,
    key: "adhesion",
    element: <AdhesionPayement />,
    secured: true,
  },
  {
    path: ROUTE_FIN_ADHESION,
    key: "adhesion",
    element: <AdhesionEnding />,
    secured: true,
  },
  {
    path: ROUTE_HOME,
    key: "home",
    element: <Home />,
    secured: true,
    roles: ["ROLE_ADHERENTE_ACTIVE"],
    menu: {
      label: "Accueil",
      icon: { type: "jsx", value: IconHome },
      position: 1,
    },
    menuMobile: {
      label: "Accueil",
      icon: { type: "jsx", value: IconHome },
      position: 1,
    },
  },
  {
    path: ROUTE_CONNEXION_APRES_VALIDATION_EMAIL,
    key: "connexion",
    element: <AuthentificationConnexion />,
    secured: true,
  },
  {
    path: ROUTE_CONNEXION,
    key: "connexion",
    element: <AuthentificationConnexion />,
    secured: true,
    menuBurgerMobile: {
      label: "Déconnexion",
      icon: { type: "svg", value: SignOut },
      action: logout,
      position: 50,
    },
  },
];

const filterMenu = (name) => {
  const routesWithMenu = [...routes.filter((r) => r[name])];
  routesWithMenu.sort((a, b) => (a[name] && b[name] && a[name]?.position - b[name]?.position) || 0);
  return routesWithMenu;
};

export const resolvePath = (path, variables /*: Dictionary*/) => {
  if (path.indexOf(":") > -1) {
    Object.keys(variables).forEach((key) => {
      path = replaceAll(path, ":" + key, variables[key]);
    });
  }
  return path;
};

export const routesWithMenu = filterMenu("menu");
export const routesForMobileMenu = filterMenu("menuMobile");
export const routesWithMobileBurgerMenu = filterMenu("menuBurgerMobile");
export const routesWithElementsJuridiques = filterMenu("elementsJuridiques");
export default routes;