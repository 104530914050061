import Style from "./AuthentificationConnexion.module.css";
import { useEffect, useState } from "react";
import { login, hasRole } from "_core/authentification/service/AuthService";
import { useNavigate, Link, useLocation } from "react-router-dom";
import {
    ROUTE_CONNEXION,
    ROUTE_INSCRIPTION,
    ROUTE_REINITIALISATION_MDP,
    ROUTE_FIN_ADHESION,
} from "_core/router/routes";
import connexionImage from "_common/component/icon/leftSideLogin.jpg";
import vagueLogin from "_common/component/icon/vagueLoginDesktop.svg";
import LogoFdt from "_common/component/icon/logoFDT.svg";
import clsx from "clsx";
import eye from "_common/component/icon/eye.svg";
import notificationService from "_common/component/toaster/NotificationService";
import { checkConfirmationToken } from "_core/authentification/service/AuthServiceApi";

const AuthentificationConnexion = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [passwordShown, setPasswordShown] = useState(false);
    const [error, setError] = useState('');
    const [confirmationEmail, setConfirmationEmail] = useState('');
    const navigate = useNavigate();
    const location = useLocation();

    const verifyConfirmationToken = async (token, creation) => {
        if (token) {
            const result = await checkConfirmationToken(token, creation);
            if (window.location.href.match(ROUTE_CONNEXION + '/' + token)) {
                if (result.message === 'Mail utilisatrice confirmé') {
                    setConfirmationEmail("Votre inscription est confirmée");
                    notificationService.success("Votre inscription est confirmée");
                } else {
                    setConfirmationEmail("Votre lien de vérification d'adresse n'est pas reconnu, veuillez contacter le support");
                }
            }
        }
    }

    useEffect(() => {
        const pathSegments = location.pathname.split('/');
        let creation = null;
        let token = null;

        pathSegments.forEach(segment => {
            if (segment.startsWith('token=')) {
                token = `token=${segment.split('token=')[1]}`;
            } else if (segment.startsWith('creation=')) {
                creation = segment.split('creation=')[1];
            }
        });

        verifyConfirmationToken(token, creation);
    }, []);

    const submit = async (e) => {
        e.preventDefault();
        try {
            const user = await login(email, password);
            if (user && (hasRole(["ROLE_ADHERENTE"], user) && !(hasRole(["ROLE_ADHERENTE_ADHESION_ACTIVE"], user)))) {
                navigate(ROUTE_FIN_ADHESION);
            } else {
                window.location.reload();
            }
        } catch (err) {
            if (err.message === "403") {
                setError("Erreur : Veuillez confirmer votre email en cliquant dans le mail que vous avez reçu.")
            } else {
                setError("Erreur : Veuillez entrer des identifiants valides.")
            }
            setEmail('');
            setPassword('');
        }
    }

    const togglePasswordVisiblity = () => {
        setPasswordShown(!passwordShown);
    };

    return <div className={Style.ContainerLogin}>
        <h1 className="sr-only">Connexion à Femmes des Territoires</h1>
        <div className={Style.Content}>
            <div className={Style.LeftSideLogin}>
                <div className={Style.ImageBackground} />
                <img src={connexionImage} alt="" />
            </div>
            <div className={Style.RightSideLogin}>
                <img className={Style.LogoFdt} src={LogoFdt} alt="" aria-hidden="true" />
                <h2 className="sr-only">Se Connecter</h2>
                <p className={Style.BeforeInscrivezVous}>{`Si vous n'avez pas encore de compte,`} <Link className={Style.InscrivezVous} to={ROUTE_INSCRIPTION} target="_blank" rel="noopener">inscrivez-vous !</Link></p>
                <p className={Style.Confirmation}>{confirmationEmail}</p>

                <form>
                    {error && <label className={clsx("special-italic-text", "red-error")}>{error}</label>}
                    <label className={clsx(Style.Label, "label-input")} htmlFor="a11y-email" lang="en">E-mail</label>
                    <input id="a11y-email"
                        autoComplete="email"
                        type="email"
                        className={clsx("input-field", Style.EmailInputField)}
                        value={email}
                        required
                        onChange={e => setEmail(e.target.value)}
                    />
                    <label className={clsx(Style.Label, "label-input")} htmlFor="a11y-password">Mot de Passe</label>
                    <div className={Style.ContainerPassword}>
                        <input id="a11y-password"
                            autoComplete="current-password"
                            type={passwordShown ? "text" : "password"}
                            className="input-field"
                            value={password}
                            required
                            onChange={e => setPassword(e.target.value)}
                        />
                        <button type="button" className={`button-icon ${Style.buttonEye}`} onClick={togglePasswordVisiblity}>
                            <img src={eye} alt="" aria-hidden="true" />
                            <span className="sr-only">
                                {passwordShown ? "cacher" : "voir"} le mot de passe
                            </span>
                        </button>

                    </div>
                    <p><Link className={Style.MotDePasseOublie} to={ROUTE_REINITIALISATION_MDP} target="_blank" rel="noopener">Mot de passe oublié</Link></p>
                    <div className={Style.ButtonContainer}>
                        <button className={clsx(Style.ButtonConnexion, "button-green", "coloured-button", "button")} type="submit" onClick={submit}>Connexion</button>
                    </div>
                </form>
            </div>
        </div>
        <img src={vagueLogin} alt="" className={Style.VagueLogin} />
    </div>

}

export default AuthentificationConnexion;
